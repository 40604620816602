<app-hero id="home" [elementToScrollTo]="sectionToScrollTo"></app-hero>

<div #beginning class="powers" style="margin-bottom: 8em;">
    <div *ngFor="let feature of firstList" class="art" [style.order]="feature.cssOrder">
        <!-- <img #bg src="assets/img/art-second-color.svg" class="background-color"
             [class.background-color--visible]="selected[0] == feature">
        <img src="assets/img/art.svg">
        <figure class="front-icon" [innerHTML]="feature.processedIcon"></figure> -->
        <img src="{{feature.imageUrl}}" style="display: none"> <!-- preloads image, hidden-->
    </div>
    <div *ngFor="let feature of secondList" class="art" [style.order]="feature.cssOrder">
        <!-- <img #bg src="assets/img/art-second-color.svg" class="background-color"
             [class.background-color--visible]="selected[1] == feature">
        <img src="assets/img/art.svg">
        <figure class="front-icon" [innerHTML]="feature.processedIcon"></figure> -->
        <img src="{{feature.imageUrl}}" style="display: none"> <!-- preloads image, hidden-->
    </div>
</div>

<!-- scroll-margin style rule helps scrollIntoView() scroll to a position above the scroll-to target -->
<section #sectionToScrollTo class="container" id="feature-desc" style="scroll-margin: 200px;">
    <app-feature-desc [heading]="selected[0].title" [image]="selected[0].imageUrl"
                      [body]="selected[0].body">
    </app-feature-desc>
    <!-- <button class="button is-primary features__button">Learn more</button> -->
</section>

<br><br>

<section class="container" id="feature-desc">
    <app-feature-desc [heading]="selected[1].title" [image]="selected[1].imageUrl"
                      [body]="selected[1].body">
    </app-feature-desc>
</section>

<br><br>
<section class="ash">
    <div class="form-container">
        <div class="heading">
            <h2 class="line-decor">How can we help you?</h2>
            <div class="line"></div>
        </div>

        <form #item
              action="https://4l9r8qok62.execute-api.us-east-1.amazonaws.com/dev/bonitas-investments"
              method="POST" role="form">
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control is-expanded has-icons-left">
                            <input type="text" class="input" placeholder="First Name">
                            <svg class="icon svg-icon is-small is-left">
                                <use xlink:href="#icon-contact-outline"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control is-expanded has-icons-left">
                            <input type="text" class="input" placeholder="Last Name">
                            <svg class="icon svg-icon is-small is-left">
                                <use xlink:href="#icon-contact"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control is-expanded has-icons-left">
                            <input type="text" class="input" placeholder="Email">
                            <svg class="icon svg-icon is-small is-left">
                                <use xlink:href="#icon-mail"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control is-expanded has-icons-left">
                            <input type="text" class="input" placeholder="Subject">
                            <svg class="icon svg-icon is-small is-left">
                                <use xlink:href="#icon-text"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <textarea class="textarea" placeholder="Message"
                                      style="background-color: #f5f5f5ff;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <button class="button is-primary">
                                Send message
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>

</section>

<div class="container first">
    <section class="about">
        <img src="assets/img/bonitas-group.jpeg" style="width: 50px" class="logo" alt="">
        <p>
            Our commitment to our clients, our people and the community and our overall engagement
            evaluation process help us ensure that we deliver exceptional service to clients, within
            the confines of professional standards.
        </p>
    </section>
    <section class="sitemap">
        <div class="column">
            <h3 class="line-decor">Sitemap</h3>
            <div class="line"></div>
            <p><a routerLink="/home">Home</a></p>
            <p><a routerLink="/about">About</a></p>
            <p><a routerLink="/services">Services</a></p>
            <p><a routerLink="/career">Careers</a></p>
            <p><a routerLink="/contact">Contact</a></p>
        </div>
        <div class="column">
            <h3 class="line-decor">Address</h3>
            <div class="line"></div>
            <p>62B Circular Road,</p>
            <p>Elekahia Housing Estate,</p>
            <p>Port Harcourt, Nigeria.</p>
        </div>
        <div class="column">
            <h3 class="line-decor">Social</h3>
            <div class="line"></div>

            <ul class="social-media">
                <li>
                    <a href="https://web.facebook.com/Bonitas-Investments-Limited-103438581841493"
                       target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-facebook"></use>
                        </svg>
                        <span>Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/bonitasict" target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-twitter"></use>
                        </svg>
                        <span>Twitter</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/bonitasinvestments/?viewAsMember=true"
                       target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-linkedin"></use>
                        </svg>
                        <span>LinkedIn</span>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/bonitasict/" target="_blank">
                        <svg class="svg-icon">
                            <use xlink:href="#icon-instagram"></use>
                        </svg>
                        <span>Instagram</span>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</div>

<section style="background-color: black;">
    <div class="container second">
        <div>Developed by <span class="is-primary">Bonitas ICT</span> (c) 2021. All Rights Reserved
        </div>
    </div>
</section>

import { NavComponent } from './nav/nav.component';
import { CssChangeService } from './../services/css-change.service';
import { Location } from '@angular/common';
import { Component, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, AfterViewChecked {
  title = 'bonitas-ict-website';
  isDarkBg: boolean;

  @ViewChild('navigationBar') nav: NavComponent;

  constructor(private styleChanger: CssChangeService, private router: Router,
    private location: Location, private titleService: Title) {
    titleService.setTitle('Bonitas Investments');
    this.isDarkBg = false;
  }

  ngAfterViewInit(): void {
    this.styleChanger.add(this);
  }

  get isLandingPage() {
    return window.location.pathname.length == 1
      || window.location.pathname.length == 0
      || this.location.path() == '/'
      || this.location.path() == '/home';
  }

  ngOnInit(): void {
    if (this.isLandingPage)
      this.isDarkBg = false;
    else
      this.isDarkBg = true;
  }

  ngAfterViewChecked(): void {
  }
}

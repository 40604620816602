import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewChildren,
  QueryList
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

class Content {
  processedIcon: SafeHtml;
  constructor(public title: string, public icon: string, public type: string, public body?: any) { }
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterViewInit {
  aboutUs: Content[];
  @ViewChild('beginning') firstElement: ElementRef;
  @ViewChild('icon') anIcon: ElementRef;
  @ViewChildren('animatable') animatables: QueryList<ElementRef>;
  // QueryList is read only, so we use this for copying so we can remove done elements
  animatablesCopy: ElementRef[];
  callBackCount: number = 0;
  hasAnimated = false;
  initialIconColor: string;

  constructor(private sanitizer: DomSanitizer, private deviceDetector: DeviceDetectorService,
    private titleService: Title) {
    titleService.setTitle('About Us - Bonitas Investments');
    this.aboutUs = [
      new Content("Bonitas Investment", 'desktop', 'content', `
        The company, Bonitas Investments Limited (BIL) was founded on the 16th of September 
        1985 as a provider of construction, plant installation and general contracts and other 
        services. The founder believes that integrity and quality service are the twin pillars 
        of a successful business. Over three decades of excellent service delivery, BIL has 
        evolved to a group of companies with 7 subsidiaries with core services in oil and gas 
        well interventions, engineering, property development, construction, media and agriculture. 
      `),
      new Content('Mission Statement', 'Mission-Statements.png', 'image'),
      new Content('Bonitas Way', 'BW-Logo.png', 'image'),
      new Content("The Bonitas Way", 'desktop', 'content', `
          The Bonitas Way represents our key values with high level of commitment to integrity, 
          quality service, hard work, collaboration, innovation and excellence which helps us in 
          developing needed solutions through Innovation and excellence in all our fields.
          <br><br>
          <h3 style="margin: 0; text-transform: none; font-weight: bold;
          font-size: 0.85rem;">INTEGRITY</h3>
          At the heart of the Bonitas Group is our commitment to Integrity, Honesty and Trustworthiness, 
          which are integrated into all our business culture and work ethics. We believe in gaining our 
          client’s utmost trust and respect.
          
      `),
      new Content('Mission Statement', 'Mission-Statements.png', 'image'),
      new Content("", 'desktop', 'content', `
          <h3 style="margin: 0; text-transform: none; font-weight: bold;
          font-size: 0.85rem;">HARDWORK</h3>
          We believe success is a result of hardwork. This is why our strength is measured through 
          diligence in hardwork and consistency in all our activities.

          <br><br>
          <h3 style="margin: 0; text-transform: none; font-weight: bold;
          font-size: 0.85rem;">COLLABORATION</h3>
          Alone we can do little; together we can do so much. We embrace partnerships 
          and collaboration with internal and external stakeholders in order to 
          effectively and efficiently meet our client’s goals.
      `),
      new Content('Bonitas Way', 'BW-Logo.png', 'image'),
      new Content("", 'desktop', 'content', `
          <h3 style="margin: 0; text-transform: none; font-weight: bold;
          font-size: 0.85rem;">INNOVATION</h3>
          We are the change that unlock new values. With the presence of our highly motivated team 
          of professionals, we grow through innovation and creativity by turning new ideas into 
          return on investments.
      
          <br><br>
          <h3 style="margin: 0; text-transform: none; font-weight: bold;
          font-size: 0.85rem;">EXCELLENCE</h3>
          In the pursuit of providing the best in the market, we focus on providing a superior client 
          experience in all our operations. Together with our highly skilled professionals, we 
          execute projects based on best quality and timely delivery.
      `)
    ]
  }

  lift(icon, htmlDomElement) {
    icon.style.color = 'white';
    htmlDomElement.classList.add('background-color--visible');
  }

  drop(icon, htmlDomElement) {
    icon.style.color = this.initialIconColor;
    htmlDomElement.classList.remove('background-color--visible');
  }

  callback = (entries, observer) => {
    ++this.callBackCount;

    if (this.deviceDetector.isMobile())
      return; // no animation in mobile view

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        // roll in animation
        this.animatablesCopy.forEach((element, index) => {
          setTimeout(() => {
            element.nativeElement.classList.add('roll-in-right');
            element.nativeElement.classList.remove('hide-element-initially');
            setTimeout(() => {
              // remove animation, so our hover effect won't get spoilt
              element.nativeElement.classList.remove('roll-in-right')
              // remove from temporal list, so we won't animate again
              this.animatablesCopy.splice(index, 1);
            }, 1000)
          }, 180 * (index + 1))
        });
      }
    });
  };

  ngOnInit(): void {
    this.aboutUs.forEach((content) => {
      content.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
        <svg class="svg-icon">
          <use xlink:href="#icon-${content.icon}"></use>
        </svg>
      `);
      content.body = this.sanitizer.bypassSecurityTrustHtml(content.body);
    })
  }

  ngAfterViewInit(): void {
    this.initialIconColor = this.anIcon.nativeElement.style.color;
    this.animatablesCopy = this.animatables.toArray();

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.30
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // scroll to where animation will be played, after some time
    setTimeout(() => {
      this.firstElement.nativeElement
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 1200);

    // Ensure all items are visible after a long period of time (a fail safe)
    this.animatables.forEach(element => {
      setTimeout(() => {
        element.nativeElement.classList.remove('hide-element-initially');
      }, 3000);
    });
  }

}

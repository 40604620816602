<app-top-banner [img]="'assets/img/services.jpg'" [alt]="'Services'"
                [text]="'We provide a wide range of services!'"></app-top-banner>

<!-- scroll-margin style rule helps scrollIntoView() scroll to a position above the scroll-to target -->
<section #beginning class="section container" style="scroll-margin: 130px;">
    <div class="heading">
        <h2 class="line-decor">Our Services</h2>
        <div class="line"></div>
    </div>
    <div class="items-grid" style="text-transform: capitalize;">
        <article #item *ngFor="let service of ourServices" (mouseenter)="lift(icon, bg)"
                 (mouseleave)="drop(icon, bg)" (touchstart)="lift(icon, bg)" (touchend)="drop(icon, bg)"
                 class="item shadow-effect hide-element-initially">
            <div class="icon-wrapper" #icon>
                <img src="assets/img/art-right.svg">
                <img #bg src="assets/img/art-right-second-color.svg" class="background-color">
                <figure class="front-icon" [innerHTML]="service.processedIcon"></figure>
            </div>
            <h3>{{service.title}}</h3>
            <p>{{service.body}}</p>
        </article>
    </div>
</section>

import { ElementRef, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit, AfterViewInit {
  @ViewChild('item') animatable: ElementRef;
  @ViewChild('beginning') firstElement: ElementRef;
  callBackCount: number = 0;
  hasAnimated = false;

  constructor(private titleServie: Title) {
    titleServie.setTitle('Careers - Bonitas Investments');
  }

  callback = (entries, observer) => {
    ++this.callBackCount;

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        this.animatable.nativeElement.classList.add('wobble-hor-bottom');
      }
    });
  };

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // scroll to where animation will be played, after some time
    setTimeout(() => {
      this.firstElement.nativeElement
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 1200);
  }

}

<div title="{{text}}" style="width: 60%;">
    <svg width="100%">
        <defs>
            <style>
                @import url("https://fonts.googleapis.com/css?  family=Lora:400,400i,700,700i");

            </style>
        </defs>
        <text x="50%" y="60%" text-anchor="middle">
            {{text}}
        </text>
    </svg>
</div>

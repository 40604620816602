<app-top-banner [img]="'assets/img/career.jpg'" [alt]="'Career'" [text]="'Let\'s work together'">
</app-top-banner>

<section class="container desc">
    <article #item>
        <h1>Come join us!</h1>
        Competitive pay, 13-month salary, 20-day annual leave, monthly hangouts... Wouldn't you
        rather join us?
    </article>
</section>

<!-- scroll-margin style rule helps scrollIntoView() scroll to a position above the scroll-to target -->
<section #beginning class="section container squeeze" style="scroll-margin: 250px;">
    <div class="heading" style="margin-bottom: 2em;">
        <h2 class="line-decor">Upload CV</h2>
        <div class="line"></div>
    </div>

    <form action="">
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field">
                    <div class="control is-expanded has-icons-left">
                        <input type="text" class="input" placeholder="Full Name">
                        <svg class="icon svg-icon is-small is-left">
                            <use xlink:href="#icon-contact-outline"></use>
                        </svg>
                    </div>
                </div>
                <div class="field">
                    <div class="control is-expanded has-icons-left">
                        <input type="text" class="input" placeholder="Phone Number">
                        <svg class="icon svg-icon is-small is-left">
                            <use xlink:href="#icon-phone"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field" style="flex-basis: 50%;">
                    <div class="control is-expanded has-icons-left">
                        <input type="text" class="input" placeholder="Email">
                        <svg class="icon svg-icon is-small is-left">
                            <use xlink:href="#icon-mail"></use>
                        </svg>
                    </div>
                </div>

                <div class="file has-name is-fullwidth is-primary"
                     style="height: 40px; flex-basis: 50%; overflow-x: hidden; font-size: 1rem;">
                    <label class="file-label">
                        <input class="input file-input" type="file" name="resume">
                        <span class="file-cta" style="height: 40px; padding-left: 5px;">
                            <svg class="file-icon svg-icon" style="width: 2em; height: 2em">
                                <use xlink:href="#icon-mail"></use>
                            </svg>
                            <span class="file-label">
                                Choose a file…
                            </span>
                        </span>
                        <span class="file-name" style="color: #b5b5b5;">
                            File...
                        </span>
                    </label>
                </div>

                <!-- <div class="field">
                        <div class="control is-expanded has-icons-left">
                            <input type="text" class="input" placeholder="Subject">
                            <svg class="icon svg-icon is-small is-left">
                                <use xlink:href="#icon-text"></use>
                            </svg>
                        </div>
                    </div> -->

            </div>
        </div>

        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field">
                    <div class="control">
                        <textarea class="textarea"
                                  placeholder="Tell us a little about yourself"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="field is-horizontal">
            <div class="field-body">
                <div class="field">
                    <div class="control">
                        <button class="button is-primary">
                            Upload CV
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </form>
</section>

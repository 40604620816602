<app-top-banner [img]="'assets/img/contact.jpg'" [alt]="'Contact'"
                [text]="'Keep in touch with us!'"></app-top-banner>

<section id="contact" class="section container">
    <div class="contacts">
        <section style="flex-basis: 50%;">
            <div class="heading">
                <h2 class="line-decor">Contact Info</h2>
                <div class="line"></div>
            </div>

            <div #beginning #item class="hide-element-initially" style="scroll-margin: 200px;">
                <article class="item" (mouseenter)="lift(icon0, bg0)"
                         (mouseleave)="drop(icon0, bg0)">
                    <div class="icon-wrapper" #icon0>
                        <img src="assets/img/art-right.svg">
                        <img #bg0 src="assets/img/art-right-second-color.svg"
                             class="background-color">
                        <figure class="front-icon">
                            <svg class="svg-icon">
                                <use xlink:href="#icon-office-home"></use>
                            </svg>
                        </figure>
                    </div>
                    <div class="stack">
                        <h3>Visit Us</h3>
                        <p class="smaller-font">62B Circular Road, Elekahia Housing Estate, Port
                            Harcourt, Nigeria</p>
                    </div>
                </article>
                <article class="item" (mouseenter)="lift(icon1,bg1)"
                         (mouseleave)="drop(icon1, bg1)">
                    <div class="icon-wrapper" #icon1>
                        <img src="assets/img/art-right.svg">
                        <img #bg1 src="assets/img/art-right-second-color.svg"
                             class="background-color">
                        <figure class="front-icon">
                            <svg class="svg-icon">
                                <use xlink:href="#icon-mail"></use>
                            </svg>
                        </figure>
                    </div>
                    <div class="stack">
                        <h3>Mail Us</h3>
                        <p>info@bonitasinvestments.com</p>
                    </div>
                </article>
                <article class="item" (mouseenter)="lift(icon2, bg2)"
                         (mouseleave)="drop(icon2, bg2)">
                    <div class="icon-wrapper" #icon2>
                        <img src="assets/img/art-right.svg">
                        <img #bg2 src="assets/img/art-right-second-color.svg"
                             class="background-color">
                        <figure class="front-icon">
                            <svg class="svg-icon">
                                <use xlink:href="#icon-mobile"></use>
                            </svg>
                        </figure>
                    </div>
                    <div class="stack">
                        <h3>Call Us</h3>
                        <p>+234 7033 456 7788</p>
                    </div>
                </article>
                <article class="item" (mouseenter)="lift(icon3, bg3)"
                         (mouseleave)="drop(icon3, bg3)">
                    <div class="icon-wrapper" #icon3>
                        <img src="assets/img/art-right.svg">
                        <img #bg3 src="assets/img/art-right-second-color.svg"
                             class="background-color">
                        <figure class="front-icon">
                            <svg class="svg-icon">
                                <use xlink:href="#icon-clock"></use>
                            </svg>
                        </figure>
                    </div>
                    <div class="stack">
                        <h3>Work Hours</h3>
                        <p>Mon - Fri: 08 Am - 05 Pm</p>
                    </div>
                </article>
            </div>

        </section>

        <section style="flex-basis: 50%;">
            <div class="heading">
                <h2 class="line-decor">Leave a Message</h2>
                <div class="line"></div>
            </div>

            <form #item
                  action="https://4l9r8qok62.execute-api.us-east-1.amazonaws.com/dev/bonitas-investments"
                  method="POST" role="form" class="hide-element-initially">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div class="control is-expanded has-icons-left">
                                <input type="text" class="input" placeholder="First Name">
                                <svg class="icon svg-icon is-small is-left">
                                    <use xlink:href="#icon-contact-outline"></use>
                                </svg>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control is-expanded has-icons-left">
                                <input type="text" class="input" placeholder="Last Name">
                                <svg class="icon svg-icon is-small is-left">
                                    <use xlink:href="#icon-contact"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div class="control is-expanded has-icons-left">
                                <input type="text" class="input" placeholder="Email">
                                <svg class="icon svg-icon is-small is-left">
                                    <use xlink:href="#icon-mail"></use>
                                </svg>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control is-expanded has-icons-left">
                                <input type="text" class="input" placeholder="Subject">
                                <svg class="icon svg-icon is-small is-left">
                                    <use xlink:href="#icon-text"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <textarea class="textarea" placeholder="Message"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <button class="button is-primary">
                                    Send message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>

        </section>
    </div>

    <div style="text-align: center; margin: 2em auto;">
        <iframe style="width: 100%;"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.3300971224103!2d7.058292598332029!3d6.220129726277698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104383d4903474fd%3A0x30685581302e0dc9!2sBonitas%20ICT!5e0!3m2!1sen!2sng!4v1618480740732!5m2!1sen!2sng"
                width="100%" height="500" style="border:0;" allowfullscreen=""
                loading="lazy"></iframe>
    </div>
</section>

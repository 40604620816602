import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Content } from 'src/utilities/content';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit, AfterViewInit {
  ourServices: Content[];
  @ViewChild('beginning') firstElement: ElementRef;
  @ViewChild('icon') anIcon: ElementRef;
  @ViewChildren('item') animatables: QueryList<ElementRef>;
  // QueryList is read only, so we use this for copying so we can remove done elements
  animatablesCopy: ElementRef[];
  callBackCount: number = 0;
  hasAnimated = false;
  initialIconColor: string;

  constructor(private sanitizer: DomSanitizer, private deviceDetector: DeviceDetectorService,
    private titleService: Title) {
    titleService.setTitle('Our Services - Bonitas Investments')
    this.ourServices = [
      new Content('Accounting and Financial Support Services', 'cash-multiple', `
        Management Financial Reporting services, Accounting and finance teams support, 
        Business advisory, Company chart of account mapping
      `),
      new Content('Tax Services', 'currency-usd-off', `
          Tax return preparation service (direct taxes and indirect taxes),
          Review services for tax return
      `),
      new Content('Business and Management Consulting Services', 'domain', `
        Budgets and cash flow forecasts, Audit planning and preparation,
        Business restructuring and setting up of finance team, Financial Advisory, Corporate Finance,
        Deal Structuring, Infrastructure Finance, Project Finance, Treasury Management
      `),
      new Content('Payroll Services', 'slot-machine-outline', `
        Analysis of Payroll Processes, Analysis of the payroll data received from our clients in terms 
        of the requirements of income tax, Performance of technical payroll calculations, 
        Preparation of payroll reports specific to our clients
      `),
      new Content('Investment Engineering Services', 'tools', `
        Integrated Intervention Engineering Services, Training & Manpower Services, Construction
      `),
      new Content('Business Advisory', 'head-lightbulb-outline', `
        Financial records analysis, Processes improvement, Operational changes recommendation,
        Sales evaluation and improvement, Marketing and brnading strategies, Project preparatory and
        productivity reports, Compliance and quality reassurance
      `),
    ]
  }

  lift(icon, htmlDomElement) {
    icon.style.color = 'white';
    htmlDomElement.classList.add('background-color--visible');
  }

  drop(icon, htmlDomElement) {
    icon.style.color = this.initialIconColor;
    htmlDomElement.classList.remove('background-color--visible');
  }

  callback = (entries, observer) => {
    ++this.callBackCount;

    if (this.deviceDetector.isMobile())
      return; // no animation in mobile view

    entries.forEach(entry => {
      if (!this.hasAnimated && this.callBackCount > 1 && entry.isIntersecting == true) {
        this.hasAnimated = true;
        // animation action!
        this.animatablesCopy.forEach((element, index) => {
          setTimeout(() => {
            element.nativeElement.classList.add('flip-in-hor-bottom');
            element.nativeElement.classList.remove('hide-element-initially');
            // remove, so our hover effect won't spoil
            setTimeout(() => {
              element.nativeElement.classList.remove('flip-in-hor-bottom')
              this.animatablesCopy.splice(index, 1);
            }, 600)
          }, 200 * (index + 1))
        });
      }
    });
  };

  ngOnInit(): void {
    this.ourServices.forEach((service) => {
      service.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
        <svg class="svg-icon">
            <use xlink:href="#icon-${service.icon}"></use>
        </svg>
      `);
    })
  }

  ngAfterViewInit(): void {
    this.initialIconColor = this.anIcon.nativeElement.style.color;
    this.animatablesCopy = this.animatables.toArray();

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.60
    }
    let observer = new IntersectionObserver(this.callback, options);
    observer.observe(this.firstElement.nativeElement);

    // scroll to where animation will be played, after some time
    setTimeout(() => {
      this.firstElement.nativeElement
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }, 1200);

    // Ensure all items are visible after a long period of time (a fail safe)
    this.animatables.forEach(element => {
      setTimeout(() => {
        element.nativeElement.classList.remove('hide-element-initially');
      }, 3000);
    });
  }

}
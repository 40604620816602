<header class="header">
    <div #hero id="hero" class="hero"></div>

    <div class="inner">
        <h1 class="title" style="text-align: center;">
            Welcome To
            <span class="yellow">Bonitas</span>
            <span class="blue">Investments</span>
        </h1>
        <h3>We Are Ready To Serve You Best!</h3>
        <button class="button is-primary" (click)="scrollToElement()"
                style="background-color: #faab24;">Learn More</button>
    </div>

    <div class="mobile-button">
        <button class="button is-primary is-mobile" (click)="scrollToElement()"
                style="background-color: #faab24;">Learn More</button>
    </div>

    <video autoplay muted loop class="hero-art">
        <source src="assets/vid/office-scrappers.mp4" type="video/mp4">
        Your browser does not support HTML5 video.
    </video>
</header>

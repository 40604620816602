import { CssChangeService } from './../../services/css-change.service';
import { AfterViewInit, ElementRef } from '@angular/core';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const TOLERANCE_ZONE = 100;

class Feature {
  processedIcon: SafeHtml;
  constructor(public imageUrl: string, public title: string,
    public icon: string, public cssOrder: number, public body: string) { }
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
  private distanceFromTop;

  selected: any;
  firstList: any;
  firstListCurrentIndex = 0;

  secondList: any;
  secondListCurrentIndex = 1;

  @ViewChild('beginning') firstElement: ElementRef;

  constructor(private cssChangeService: CssChangeService, private sanitizer: DomSanitizer) {
    this.firstList = [
      new Feature('assets/img/features1.jpg', 'Introduction', 'desktop', 0, `
        The company, Bonitas Investments Limited (BIL) was founded on the 16th of September 
        1985 as a provider of construction, plant installation and general contracts and other 
        services. The founder believes that integrity and quality service are the twin pillars 
        of a successful business. Over three decades of excellent service delivery, BIL has 
        established 9 subsidiaries with core services in oil and gas well interventions, engineering, 
        property development, construction, media, marketing and agriculture. 
      `),

      new Feature('assets/img/features2.jpg', 'Introduction', 'mobile', 3, `
        As BIL staff and client base have grown over the years, the management decided to focus 
        its core business and competence on providing financial services to SMEs and growing
        businesses with the aim of helping these businesses to becom bankable.
      `),
    ];

    this.secondList = [
      new Feature('assets/img/features3.jpg', 'Who We Are', 'cloud', 1, `
        BIL is large enough to offer a full range of independent and objective accounting, 
        payroll, tax and financial advisory services yet small enough to attend to your 
        specific needs by providing bespoke financial services tailored to the needs of 
        your business through highly trained, skilled and motivated professionals.
      `),

      new Feature('assets/img/features4.jpg', 'Our Commitment', 'database', 2, `
        Our commitment to our clients, our people and the community and our overall engagement 
        evaluation process help us ensure that we deliver exceptional service to clients, within 
        the confines of professional standards.
      `),
    ];
  }

  mouseEnter(htmlDomElement) {
    htmlDomElement.classList.add('background-color--visible');
  }

  mouseLeave(htmlDomElement) {
    htmlDomElement.classList.remove('background-color--visible');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (window.scrollY >= this.distanceFromTop - TOLERANCE_ZONE) {
      this.cssChangeService.component.isDarkBg = true;
    } else if (window.scrollY < this.distanceFromTop - TOLERANCE_ZONE) {
      this.cssChangeService.component.isDarkBg = false;
    }
  }

  ngOnInit(): void {
    this.firstList.forEach((feature) => {
      feature.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
          <svg class="svg-icon">
              <use xlink:href="#icon-${feature.icon}"></use>
          </svg>
          <figcaption>${feature.title}</figcaption>
      `);
    });
    this.secondList.forEach((feature) => {
      feature.processedIcon = this.sanitizer.bypassSecurityTrustHtml(`
          <svg class="svg-icon">
              <use xlink:href="#icon-${feature.icon}"></use>
          </svg>
          <figcaption>${feature.title}</figcaption>
      `);
    });

    this.selected = [
      this.firstList[0],
      this.secondList[1]
    ];

    setInterval(() => {
      let active = this.firstListCurrentIndex;
      active = Math.abs(active - 1);
      this.firstListCurrentIndex = active;
      this.selected[0] = this.firstList[active];

      active = this.secondListCurrentIndex;
      active = Math.abs(active - 1);
      this.secondListCurrentIndex = active;
      this.selected[1] = this.secondList[active];
    }, 4000);
  }

  ngAfterViewInit(): void {
    this.distanceFromTop = this.firstElement.nativeElement.getBoundingClientRect().top;
  }

}

import { Component, OnInit, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, AfterViewInit {
  @Input() elementToScrollTo;

  constructor() { }

  scrollToElement(): void {
    this.elementToScrollTo.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

}
